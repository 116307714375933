<template>
  <div>
    <form @submit.prevent="save()">
      <input
          type="file"
          id="fileInput"
          ref="fileInput"
          class="invoice"
          accept=".xls, .xlsx"
          @change="previewFileAll"
      />
      <button type="submit" :disabled="disableBtn" class="header__btn right-item default-btn">Загрузить файл</button>
    </form>
  </div>
</template>

<script>
export default {
  data () {
    return {
      documents: [],
      disableBtn: true
    }
  },
  methods: {
    previewFileAll() {
      this.documents = this.$refs.fileInput.files;
      this.disableBtn = false
    },
    save() {
      const formData = new FormData();
      Object.values(this.documents).forEach((e, index) => {
        formData.set(`documents[${index}]`, this.documents[index]);
      });
      this.$api.post("/web/import-categories",  formData )
          .then(() => {
            this.$toast.success("Успешно!");
          })
          .catch((e) => {
            this.$store.commit("setToastData", {
              text: e.response.data.message,
              position: "error",
            });
            this.$toast.error(<toast-block />);
            if (
                e.message.split(" ").reverse()[0] === 500 ||
                e.message.split(" ").reverse()[0] === 401
            ) {
              this.$router.push(`/error/${e.message.split(" ").reverse()[0]}`);
            }
          });
    }
  }
}
</script>

<style scoped>

</style>